import React from 'react';
import { MarkdownContent } from '@/components/markdown-content';
import { Grid } from '@mui/material';
import { HomeSection5 } from '@/api/home';
import { navigate } from 'gatsby';
import { MainColumn } from '@/components/main-column';
import { TrackedButton } from '@/components/tracked-button';
import { CaseStudyCard } from '@/components/case-study';

export type Props = {
  strapiSection: HomeSection5;
};

export const Section5 = ({ strapiSection }: Props) => (
  <MainColumn
    sx={{
      pt: { xs: 8, md: 10 },
    }}
  >
    <Grid
      container
      alignContent="center"
      justifyContent="space-between"
      sx={{
        mb: { md: 5 },
      }}
    >
      <Grid item sx={{ pr: 3, mb: 5 }}>
        <MarkdownContent
          content={strapiSection?.paragraph}
          sx={{
            'h1, h2, h3, h4, h5, h6': {
              mb: 3,
            },
            'p:last-of-type': {
              mb: '0px',
            },
          }}
        />
      </Grid>
      <Grid item sx={{ mb: 5 }}>
        <Grid
          container
          flexDirection={'column'}
          height={'100%'}
          justifyContent={'center'}
        >
          <TrackedButton
            variant="outlined"
            onClick={() => navigate(strapiSection?.button?.link)}
          >
            {strapiSection?.button?.text}
          </TrackedButton>
        </Grid>
      </Grid>
    </Grid>

    <Grid
      container
      alignItems="center"
      alignContent="center"
      spacing={{ xs: 0, sm: 4 }}
      sx={{
        mb: { xs: 6, md: 9 },
      }}
    >
      {strapiSection?.caseStudies?.data?.map(
        ({
          attributes: { isSeparateSubpage, gradientColor, slug, icon, image },
        }) => (
          <CaseStudyCard
            isSeparateSubpage={isSeparateSubpage}
            key={`${image?.data?.attributes?.url}`}
            gradientColor={gradientColor}
            slug={slug}
            icon={icon?.data}
            url={image?.data?.attributes?.url || ''}
          />
        ),
      )}
    </Grid>
  </MainColumn>
);
