import { HomeSection2 } from '../../../../api/home';
import { Container, Box, Grid, Typography, useTheme, Breakpoint } from '@mui/material';
import { MarkdownContent } from '@/components/markdown-content';
import React from 'react';
import { MainColumn } from '@/components/main-column';
import { computedMainColumnGutters } from '@/gatsby-theme-material-ui-top-layout/spacing';

export type Props = {
  strapiSection: HomeSection2;
};

export const section1Overlap: { [key in Breakpoint]?: number } = {
  md: 160,
  lg: 60,
};

export const Section2 = ({ strapiSection }: Props) => {
  const theme = useTheme();

  return (
    <Container
      maxWidth={false}
      disableGutters
      sx={{
        position: 'relative',
        mt: {
          md: `-${section1Overlap.md || 0}px`,
          lg: `-${section1Overlap.lg || 0}px`,
        },
        zIndex: 1,
      }}
    >
      <Box
        sx={{
          display: { xs: 'none', sm: 'block', lg: 'none' },
          position: 'absolute',
          top: 0,
          left: 0,
          width: { xs: '100%', md: '50%' },
          height: '100%',
          backgroundColor: 'greyscale.persian',
          zIndex: -1,
        }}
      />
      <MainColumn
        sx={{
          position: 'relative',
          px: { xs: '0px' },
          mb: { md: 10 },
        }}
      >
        <Grid container>
          <Grid item md={9}>
            <MainColumn
              sx={{
                position: 'relative',
                py: { xs: 8, md: 9 },
                px: { sm: '0px', lg: 9 },
                [theme.breakpoints.only('md')]: {
                  pl: '0px !important',
                },
                ml: '0px',
                backgroundColor: 'greyscale.persian',
              }}
            >
              <MarkdownContent
                content={strapiSection?.paragraph}
                sx={{
                  mb: 4,
                  '> *': { color: 'white !important' },
                  'h1, h2, h3, h4, h5, h6': {
                    mb: 4,
                  },
                }}
              />
              <Grid
                container
                columnGap={2}
                rowGap={2}
                sx={{
                  flexWrap: { sm: 'nowrap' },
                }}
              >
                {strapiSection?.heroTable?.map(
                  ({ title, subtitle, value }, index) => (
                    <Grid item key={index} xs={12} sm={4} flexGrow={1}>
                      <Box
                        sx={{
                          height: '100%',
                          color: 'greyscale.white',
                          borderWidth: 1,
                          borderStyle: 'solid',
                          borderColor: 'greyscale.indigo',
                          borderRadius: 3,
                          pl: 2,
                          pr: 0,
                          py: 3,
                        }}
                      >
                        <Typography
                          variant="h5"
                          sx={{
                            display: { md: 'block', lg: 'inline-block' },
                            pb: 2,
                          }}
                        >
                          {value}
                          <Typography
                            component="br"
                            sx={{
                              display: {
                                xs: 'none',
                                sm: 'inline',
                                lg: 'none',
                              },
                            }}
                          />
                          <Typography
                            component="span"
                            variant="body2"
                            sx={{
                              ml: { xs: 0, sm: '0px', lg: 0 },
                            }}
                          >
                            {title}
                          </Typography>
                        </Typography>
                        <Typography
                          variant="body2"
                          sx={{ fontWeight: 'medium' }}
                        >
                          {subtitle}
                        </Typography>
                      </Box>
                    </Grid>
                  ),
                )}
              </Grid>
            </MainColumn>
          </Grid>
          <Grid item md={3} sx={{ position: 'relative' }}>
            <Box
              sx={{
                position: 'absolute',
                bottom: 0,
                right: 0,
                display: { xs: 'none', md: 'block' },
                width: {
                  md: `calc(100% + ${computedMainColumnGutters.md || '0px'})`,
                  lg: '100%',
                },
                height: {
                  md: `calc(100% - ${section1Overlap.md || 0}px)`,
                  lg: `calc(100% - ${section1Overlap.lg || 0}px)`,
                },
                marginRight: {
                  md: `-${computedMainColumnGutters.md || '0px'}`,
                  lg: '0px',
                },
                backgroundPosition: 'center center',
                backgroundImage: `url('${strapiSection?.image?.data?.attributes?.url}')`,
                backgroundSize: 'cover',
                backgroundColor: 'black',
              }}
            />
          </Grid>
        </Grid>
      </MainColumn>
    </Container>
  );
};
