import React from 'react';
import {
  Card,
  CardContent,
  Container,
  Grid,
  Typography,
} from '@mui/material';
import { MarkdownContent } from '@/components/markdown-content';
import { HomeSection6 } from '../../../../api/home';
import { useTheme } from '@mui/system';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/free-mode';
import { FreeMode } from 'swiper';
import { MainColumn } from '@/components/main-column';
import { GatsbyImage } from 'gatsby-plugin-image';
import getGatsbyImageData from '@/utils/getGatsbyImageData';
import { StyledImageLogo, StyledSwiperContainer } from './Home.style';

export type Props = {
  strapiSection: HomeSection6;
};

export const Section6 = ({ strapiSection }: Props) => {
  const theme = useTheme();

  return (
    <Container
      maxWidth={false}
      disableGutters
      sx={{
        position: 'relative',
        backgroundColor: 'greyscale.persian',
        color: 'greyscale.white',
      }}
    >
      <MainColumn
        sx={{
          pt: { xs: 8, md: '120px' },
          mb: { xs: 4, md: 9 },
        }}
      >
        <MarkdownContent
          content={strapiSection?.paragraph}
          sx={{
            '> *': { color: 'white !important' },
            'h1, h2, h3, h4, h5, h6': {
              mb: 3,
            },
            'p:last-of-type': {
              mb: '0px',
            },
          }}
        />
      </MainColumn>
      <Container
        maxWidth={false}
        disableGutters
        sx={{
          position: 'absolute',
          bottom: -1,
          left: 0,
          width: '100%',
          height: { xs: 290, sm: 480, md: 530, lg: 530 },
          backgroundColor: 'greyscale.white',
        }}
      />
      <StyledSwiperContainer>
        <Swiper
          className={'swiper-container'}
          spaceBetween={parseInt(theme.spacing(3))}
          modules={[FreeMode]}
          freeMode
          breakpoints={{
            [theme.breakpoints.values.xs]: {
              slidesPerView: 1.1,
            },
            [theme.breakpoints.values.sm]: {
              slidesPerView: 1.8,
            },
            [theme.breakpoints.values.md]: {
              slidesPerView: 2.5,
            },
          }}
        >
          {strapiSection?.quotes?.map(
            ({ text, author, companyLogo }, idx) => {
              const companyLogoData = getGatsbyImageData(companyLogo.data);
              return (
                <SwiperSlide key={idx}>
                  <Card
                    sx={{
                      height: '100%',
                      borderRadius: '0px',
                      boxShadow:
                        '0px 100px 85px rgba(21, 41, 70, 0.07), 0px 40.1844px 48.1828px rgba(21, 41, 70, 0.0440421), 0px 21.8835px 34.7059px rgba(21, 41, 70, 0.0338727), 0px 12.4086px 26.0544px rgba(21, 41, 70, 0.0271923), 0px 6.63101px 18.6946px rgba(21, 41, 70, 0.0216728), 0px 2.76664px 10.6609px rgba(21, 41, 70, 0.0155322)',
                    }}
                  >
                    <CardContent
                      sx={{
                        position: 'relative',
                        p: {
                          xs: theme.spacing(2) + '!important',
                          sm: theme.spacing(5) + '!important',
                          md: theme.spacing(6) + '!important',
                        },
                        cursor: 'url(carousel-cursor.svg), auto',
                      }}
                    >
                      <Grid
                        container
                        sx={{
                          height: 64,
                          alignItems: 'center',
                          mb: { xs: 3, md: 5 },
                        }}
                      >
                        <Grid item sx={{ fontSize: 0 }}>
                          {companyLogoData && (
                            <StyledImageLogo
                              alt={companyLogo.data.attributes.alternativeText || ''}
                              image={companyLogoData}
                            />)}
                        </Grid>
                      </Grid>
                      <Typography
                        sx={{
                          color: 'greyscale.persian',
                          mb: { xs: 3, md: 5 },
                        }}
                      >
                        {' '}
                        {text}
                      </Typography>
                      <Grid
                        container
                        sx={{
                          alignItems: 'flex-start',
                          flexWrap: 'nowrap',
                        }}
                      >
                        <Grid item>
                          <Typography
                            variant="body2"
                            sx={{
                              fontWeight: 'medium',
                              color: 'greyscale.steel',
                            }}
                          >
                            {' '}
                            {author}
                          </Typography>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                </SwiperSlide>
              );
            })
          }
        </Swiper>
      </StyledSwiperContainer>
    </Container>
  );
};
