import { styled } from '@mui/system';
import { GatsbyImage } from 'gatsby-plugin-image';
import { Box } from '@mui/material';
import { computedMainColumnGutters } from '@/gatsby-theme-material-ui-top-layout/spacing';

export const StyledImage = styled(GatsbyImage)(({ theme }) => ({
  maxHeight: '100px',
  [theme.breakpoints.up('xs')]: {
    maxWidth: theme.spacing(6),
  },
  [theme.breakpoints.up('sm')]: {
    maxWidth: 'none',
  },
}));

export const StyledImageLogo = styled(GatsbyImage)({
  maxHeight: '64px',
});

export const StyledSwiperContainer = styled(Box)(({ theme }) => ({
  '.swiper-container': {
    paddingLeft: computedMainColumnGutters.xs,
    paddingRight: computedMainColumnGutters.xs,
    paddingBottom: 200,
    marginBottom: -180,

    [theme.breakpoints.up('sm')]: {
      paddingLeft: computedMainColumnGutters.sm,
      paddingRight: computedMainColumnGutters.sm,
    },

    [theme.breakpoints.up('md')]: {
      paddingLeft: computedMainColumnGutters.md,
      paddingRight: computedMainColumnGutters.md,
    },

    [theme.breakpoints.up('lg')]: {
      paddingLeft: `calc(${computedMainColumnGutters.lg || '0px'} + (100vw - ${theme.breakpoints.values.lg}px) / 2)`,
      paddingRight: `calc(${computedMainColumnGutters.lg || '0px'} + (100vw - ${theme.breakpoints.values.lg}px) / 2)`,
    },
  },

  '.swiper-slide': {
    height: 'auto',
  },
}));
