import {
  Box,
  Chip,
  Container,
  Grid,
  keyframes,
  Link,
  Typography,
} from '@mui/material';
import React, { FC } from 'react';
import { Link as GatsbyLink, navigate } from 'gatsby';
import hexToRgba from 'hex-to-rgba';
import { Pill, StrapiImage } from '@/api/types';
import { LinkRightArrow } from '@/icons/LinkRightArrow';
import { ArrowInCircle } from '@/icons/ArrowInCircle';
import getGatsbyImageData from '@/utils/getGatsbyImageData';
import { StyledImageIcon } from '@/components/case-study/CaseStudy.style';

type ICaseStudyCard = {
  url: string;
  slug: string;
  icon: StrapiImage;
  gradientColor?: string;
  pills?: Array<Pill>;
  description?: string;
  variant?: CaseStudyCardVariants;
  isSeparateSubpage?: boolean;
  index?: number;
  name?: string;
  expanded?: boolean;
  onClick?: () => void;
};

export enum CaseStudyCardVariants {
  OurWorkMain = 'OurWorkMain',
  OurWorkOther = 'OurWorkOther',
  Home = 'Home',
}

export const CaseStudyCard: FC<ICaseStudyCard> = ({
  url,
  slug,
  isSeparateSubpage,
  gradientColor,
  icon,
  pills,
  description,
  variant = CaseStudyCardVariants.Home,
  expanded,
  onClick,
  index = 0,
  name,
}) => {
  const getCaseStudyGridProps = () => {
    if (variant === CaseStudyCardVariants.OurWorkMain) {
      return {
        sm: 12,
        xs: 12,
        md: 6,
        lg: 6,
        sx: {
          mt: index !== 0 ? { xs: '48px', md: '0px' } : '0px',
          pl: { md: index % 2 !== 0 ? '42px' : '0px' },
          pr: { md: index % 2 === 0 ? '42px' : '0px' },
          py: {
            md:
              (index % 2 !== 0 && index % 3 !== 0) ||
              (index % 3 !== 0 && (index + 1) % 2 !== 0)
                ? '48px'
                : '0px',
          },
        },
      };
    } else if (variant === CaseStudyCardVariants.OurWorkOther) {
      return { xs: 12, sm: 6, md: 6, lg: 4, sx: { mb: 4 } };
    } else {
      return { xs: 12, sm: 4, sx: { mb: 4 } };
    }
  };

  const getCaseStudyImageHeight = () =>
    CaseStudyCardVariants.OurWorkMain === variant
      ? { xs: '355px', sm: '690px', md: '500px', lg: '570px' }
      : { xs: '355px', sm: '300px', lg: '420px' };

  const getSlideKeyframe = () => {
    const slideBack = keyframes`
      from {
        top: calc(100% - 75px);
        transform: rotate(0);
      }
      to {
        transform: rotate(180deg);
        top: 32px;
      }`;

    const slide = keyframes`
      from {
        top: 32px;
        transform: rotate(180deg);
      }
      to {
        top: calc(100% - 75px);
        transform: rotate(0);
      }`;

    return expanded ? slideBack : slide;
  };

  const transition = keyframes`
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }`;

  const iconImageData = getGatsbyImageData(icon);

  return (
    <Grid item {...getCaseStudyGridProps()} onClick={onClick} m={-1}>
      <Link
        onClick={() => {
          if (
            isSeparateSubpage &&
            variant !== CaseStudyCardVariants.OurWorkOther
          ) {
            navigate(`/our-work/${slug}`);
          }
        }}
      >
        <Container
          disableGutters
          maxWidth={false}
          sx={{
            position: 'relative',
            transition: 'all 0.6s',
            '&:hover': {
              svg: {
                display: 'block',
              },
            },
          }}
        >
          {variant === CaseStudyCardVariants.OurWorkOther && (
            <Box
              sx={{
                width: '40px',
                right: 0,
                left: 0,
                margin: 'auto',
                svg: {
                  display: expanded ? 'block' : 'none',
                },
                top: 0,
                zIndex: 1,
                animation: `${getSlideKeyframe()} 0.5s normal forwards`,
                color: 'white',
                position: 'absolute',
              }}
            >
              <ArrowInCircle />
            </Box>
          )}
          {expanded && (
            <Box
              sx={{
                animation: `${transition} 1s normal forwards`,
                display: 'block',
                zIndex: 5,
                left: 0,
                px: 3,
                color: 'white',
                bottom: 0,
                position: 'absolute',
              }}
            >
              <Typography fontWeight={'semiBold'}>{name}</Typography>
              <Typography pt={1} pb={3} variant={'body2'}>
                {description}
              </Typography>
            </Box>
          )}
          <Box
            mb={-1}
            sx={{
              display: 'block',
              transition: 'all 0.6s',
              '&:hover': {
                backgroundSize:
                  variant === CaseStudyCardVariants.OurWorkOther
                    ? 'cover'
                    : 'auto 110%',
              },
              backgroundImage: `url(${url})`,
              backgroundRepeat: 'no-repeat',
              backgroundSize:
                variant === CaseStudyCardVariants.OurWorkOther
                  ? 'cover'
                  : 'auto 100%',
              backgroundPosition: 'center',
              objectFit: 'cover',
              minHeight: getCaseStudyImageHeight(),
              width: '100%',
            }}
          >
            <Box
              position={'absolute'}
              sx={{
                top: 0,
                height: '100%',
                transition: 'all 0.6s',
                width: '100%',
                opacity: 0.8,
                background: `linear-gradient(0deg, ${gradientColor} 0%, ${hexToRgba(
                  gradientColor || '',
                  expanded ? '0.6' : '0',
                )} 100%)`,
                '&:hover': {
                  opacity: 1,
                  background: `linear-gradient(0deg, ${gradientColor} 0%, ${hexToRgba(
                    gradientColor || '',
                    expanded ? '0.6' : '0.2',
                  )} 100%)`,
                },
              }}
            />
          </Box>
          {!expanded && iconImageData && (
            <StyledImageIcon
              alt={icon?.attributes?.alternativeText || ''}
              image={iconImageData}
            />
          )}
        </Container>
      </Link>
      {variant === CaseStudyCardVariants.OurWorkMain && description ? (
        <Container
          disableGutters
          maxWidth={false}
          sx={{ position: 'relative' }}
        >
          <Box>
            {pills?.map((pill, idx) => (
              <Chip
                key={pill?.id}
                label={pill?.text}
                sx={{
                  fontSize: '12px',
                  fontWeight: 500,
                  backgroundColor: 'greyscale.alice',
                  mt: 3,
                  mr: idx !== pills?.length - 1 ? 0 : '0px',
                }}
              />
            ))}
          </Box>
          <Typography variant={'h5'} sx={{ mb: 0, mt: 2 }}>
            {name}
          </Typography>
          <Typography sx={{ mb: 2 }}>{description}</Typography>
          <Link
            component={GatsbyLink}
            to={`/our-work/${slug}`}
            sx={{
              fontWeight: 'semiBold',
              color: 'primary.main',
            }}
          >
            Read Case Study <LinkRightArrow />
          </Link>
        </Container>
      ) : null}
    </Grid>
  );
};
