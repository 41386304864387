import { HomeSection4 } from '../../../../api/home';

export type Props = {
  strapiSection: HomeSection4;
};
// The section was removed from homepage design
// https://treeline.atlassian.net/browse/TS-561
// FIXME: Remove the section and change subsequent sections's number
//  (Changes in CMS + in react components)
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const Section4 = ({ strapiSection }: Props) => null;
