import React from 'react';

export const LinkRightArrow = () => (
  <svg
    width="16"
    height="12"
    viewBox="0 0 16 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.6039 6.30925C15.6431 6.21467 15.6649 6.11103 15.6652 6.00234C15.6652 6.00072 15.6652 5.9991 15.6652 5.99749C15.6646 5.79148 15.5857 5.58566 15.4286 5.42848L10.8571 0.857055C10.5415 0.541464 10.0299 0.541463 9.71427 0.857055C9.39868 1.17265 9.39868 1.68432 9.71427 1.99991L12.9061 5.19179L1.14284 5.19179C0.696525 5.19179 0.334717 5.5536 0.334717 5.99991C0.334716 6.44623 0.696525 6.80803 1.14284 6.80803H12.9061L9.71427 9.99991C9.39868 10.3155 9.39868 10.8272 9.71427 11.1428C10.0299 11.4584 10.5415 11.4584 10.8571 11.1428L15.4282 6.57174C15.4287 6.57116 15.4297 6.5702 15.4303 6.56962C15.5069 6.49254 15.5648 6.40386 15.6039 6.30925Z"
      fill="#541BD5"
    />
  </svg>
);
