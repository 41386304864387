import React, { useEffect, useState } from 'react';
import { Box, Modal, Typography } from '@mui/material';
import { useLocation } from '@reach/router';
import { HomeThanksForYourSubmission } from '@/api/home';
import { TrackedButton } from '@/components/tracked-button';

export type Props = {
  strapiModal: HomeThanksForYourSubmission;
};

export const ThanksForSubmissionModal = ({ strapiModal }: Props) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const location = useLocation();

  useEffect(() => {
    const params = new URLSearchParams(location.search);

    if (params.has('submission')) {
      setIsModalOpen(true);
    }
  }, [location]);

  return (
    <Modal
      open={isModalOpen}
      onClose={() => {
        setIsModalOpen(false);
        window.location.replace(location.origin);
      }}
      aria-labelledby="modal-modal-title"
      disableAutoFocus={true}
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '100%',
          px: 3,
        }}
      >
        <Box
          sx={{
            position: 'relative',
            maxWidth: 588,
            backgroundColor: 'white',
            borderRadius: 3,
            p: 3,
            pb: 10,
            mx: 'auto',
          }}
        >
          <Typography id="modal-modal-title" variant="h5">
            {strapiModal?.title}
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            {strapiModal?.content}
          </Typography>

          <TrackedButton
            onClick={() => {
              setIsModalOpen(false);
            }}
            sx={{ position: 'absolute', bottom: 0, right: 0, m: 3 }}
            variant="contained"
          >
            Close
          </TrackedButton>
        </Box>
      </Box>
    </Modal>
  );
};
