import React from 'react';
import { Box, Container, Grid, Stack, useTheme } from '@mui/material';
import { HomeSection1 } from '../../../../api/home';
import { styled } from '@mui/system';
import { MarkdownContent } from '@/components/markdown-content';
import { MENU_HEIGHT } from '@/constants';
import { navigate } from 'gatsby';
import { MainColumn } from '@/components/main-column';
import { TrackedButton } from '@/components/tracked-button';
import { section1Overlap } from './Section2';
import { GatsbyImage } from 'gatsby-plugin-image';
import getGatsbyImageData from '@/utils/getGatsbyImageData';

const StyledVideo = styled('video')({
  position: 'absolute',
  height: '100%',
  width: '100%',
  objectFit: 'cover',
});

export type Props = {
  strapiSection: HomeSection1;
};

export const Section1 = ({ strapiSection }: Props) => {
  const theme = useTheme();

  return (
    <Container
      maxWidth={false}
      disableGutters
      sx={{
        display: 'flex',
        alignItems: 'center',
        mt: `${MENU_HEIGHT}px`,
        minHeight: {
          md: `calc(100vh - ${MENU_HEIGHT}px + ${section1Overlap.md || 0}px)`,
          lg: `calc(100vh - ${MENU_HEIGHT}px + ${section1Overlap.lg || 0}px)`,
        },
        position: 'relative',
      }}
    >
      <Box
        component={'img'}
        src={`${strapiSection?.poster?.data?.attributes?.url}`}
        sx={{
          height: '100%',
          width: '100%',
          position: 'absolute',
          top: 0,
          left: 0,
          objectFit: 'cover',
        }}
      />
      <StyledVideo loop autoPlay preload="auto" muted playsInline>
        <source
          src={`${strapiSection?.fullscreenVideo?.data?.attributes?.url}`}
          type="video/mp4"
        />
      </StyledVideo>
      <Box
        sx={{
          height: '100%',
          width: '100%',
          position: 'absolute',
          top: 0,
          left: 0,
          objectFit: 'cover',
          background:
            'linear-gradient(114.91deg, #031520 18.09%, rgba(3, 21, 32, 0.3) 100%)',
        }}
      />
      <MainColumn sx={{ pt: { xs: 5, md: 11 } }}>
        <MarkdownContent
          content={strapiSection?.paragraph}
          sx={{
            position: 'relative',
            mb: 6,
            '> *': { color: 'white !important' },
            'h1, h2, h3, h4, h5, h6': {
              mb: 4,
            },
          }}
        />
        <Stack
          direction={{ xs: 'column', sm: 'row' }}
          spacing={3}
          sx={{
            mb: { xs: 5, sm: 7, md: 9 },
          }}
        >
          <TrackedButton
            sx={{ width: { xs: '100%', sm: 'auto' } }}
            variant="contained"
            onClick={() => navigate(strapiSection?.button1?.link)}
          >
            {strapiSection?.button1?.text}
          </TrackedButton>
          <TrackedButton
            sx={{
              width: { xs: '100%', sm: 'auto' },
            }}
            variant="outlined"
            onClick={() => navigate(strapiSection?.button2?.link)}
          >
            {strapiSection?.button2?.text}
          </TrackedButton>
        </Stack>
        <Grid
          container
          direction="row"
          alignItems="center"
          justifyContent={{ sm: 'space-between', md: 'space-around' }}
          spacing={{ xs: 5, sm: 0 }}
          sx={{
            position: 'relative',
            textAlign: 'center',
            pr: {
              // Disalign the logos on purpose to create the illusion
              // that they are aligned with the text above
              md: 1,
            },
            mb: {
              xs: 7,
              md: `${parseInt(theme.spacing(9)) + (section1Overlap.md || 0)}px`,
              lg: `${parseInt(theme.spacing(9)) + (section1Overlap.lg || 0)}px`,
            },
          }}
        >
          {strapiSection?.ourClientLogos?.data
            ?.filter((image) => !!image)
            ?.map((image, index) => {
              const imageData = getGatsbyImageData(image);
              return (
                imageData && (
                  <Grid item key={`${image.url}${index}`} xs={6} sm="auto">
                    <GatsbyImage
                      image={imageData}
                      alt={image.attributes?.alternativeText || ''}
                      loading="eager"
                    />
                  </Grid>
                )
              );
            })
          }
        </Grid>
      </MainColumn>
    </Container>
  );
};
