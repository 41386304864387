import React from 'react';

export const ArrowInCircle = () => (
  <svg
    width="41"
    height="40"
    viewBox="0 0 41 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.6677 39C31.1612 39 39.668 30.4933 39.668 19.9997C39.668 9.50612 31.1612 0.999389 20.6677 0.999388C10.1741 0.999387 1.66736 9.50611 1.66736 19.9997C1.66736 30.4933 10.1741 39 20.6677 39ZM40.668 19.9997C40.668 31.0456 31.7135 40 20.6677 40C9.6218 40 0.667359 31.0456 0.66736 19.9997C0.667361 8.95383 9.6218 -0.000613066 20.6677 -0.0006121C31.7135 -0.000611134 40.668 8.95383 40.668 19.9997ZM26.8731 18.4024C26.6778 18.5976 26.3612 18.5976 26.166 18.4024L21.168 13.4044L21.168 27.8008C21.168 28.0769 20.9441 28.3008 20.668 28.3008C20.3918 28.3008 20.168 28.0769 20.168 27.8008L20.168 13.4073L15.1729 18.4024C14.9776 18.5976 14.6611 18.5976 14.4658 18.4024C14.2705 18.2071 14.2705 17.8905 14.4658 17.6953L20.3159 11.8452C20.5111 11.6499 20.8277 11.6499 21.023 11.8452L26.8731 17.6953C27.0683 17.8905 27.0683 18.2071 26.8731 18.4024Z"
      fill="white"
    />
  </svg>
);
