import React from 'react';
import { HomePageResponse } from '../../api/home';
import { Section1 } from '@/components/sections/home/Section1';
import { Section2 } from '@/components/sections/home/Section2';
import { Section3 } from '@/components/sections/home/Section3';
import { Section4 } from '@/components/sections/home/Section4';
import { Section5 } from '@/components/sections/home/Section5';
import { Section6 } from '@/components/sections/home/Section6';
import { StrapiMenu } from '../../api/menu';
import { Menu } from '@/components/menu';
import { StrapiFooter } from '../../api/footer';
import { Footer } from '@/components/footer';
import { ThanksForSubmissionModal } from '@/components/thanks-for-submission-modal';
import { Head as HeadComponent } from '@/components/head';

export default function Home({
  pageContext: {
    strapiHomePage: {
      metadata,
      section1,
      section2,
      section3,
      section4,
      section5,
      section6,
      thanksForYourSubmission,
    },
    strapiMenu,
    strapiFooter,
  },
}: {
  pageContext: {
    strapiHomePage: HomePageResponse;
    strapiMenu: StrapiMenu;
    strapiFooter: StrapiFooter;
  };
}) {
  const headMeta = {
    title: 'Treeline Interactive',
    meta: {
      title: 'Treeline Interactive: Custom development for mobile apps, web applications, and the Internet of Things',
      description: 'Custom development firm based in San Diego, CA, specializing in the Internet of Things, mobile apps, and web applications.',
      keywords: 'mobile app development, mobile application development, android app development san diego, mobile app development san diego, mobile application development san diego, ios developer san diego, ios development san diego',
    },
  };

  return (
    <>
      <HeadComponent
        defaultTitle={headMeta.title}
        defaultMeta={headMeta.meta}
        metadata={metadata}
        image={metadata?.OGImage?.data?.attributes.url
          || strapiMenu.logo.data.attributes.url} />
      <Menu strapiMenu={strapiMenu} whiteBackground />
      <ThanksForSubmissionModal strapiModal={thanksForYourSubmission} />
      <Section1 strapiSection={section1} />
      <Section2 strapiSection={section2} />
      <Section3 strapiSection={section3} />
      <Section4 strapiSection={section4} />
      <Section5 strapiSection={section5} />
      <Section6 strapiSection={section6} />
      <Footer strapiFooter={strapiFooter} />
    </>
  );
}
