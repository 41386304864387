import { styled } from '@mui/system';
import { keyframes } from '@mui/material';
import { GatsbyImage } from 'gatsby-plugin-image';

const transition = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }`;

export const StyledImageIcon = styled(GatsbyImage)(({ theme }) => ({
  animation: `${transition} 1s normal forwards`,
  position: 'absolute',
  left: '50%',
  top: '50%',
  [theme.breakpoints.up('xs')]: {
    transform: 'translate(-50%, -50%)',
  },
  [theme.breakpoints.up('sm')]: {
    transform: 'translate(-50%, -50%) scale(0.7)',
  },
  [theme.breakpoints.up('md')]: {
    transform: 'translate(-50%, -50%)',
  },
}));
