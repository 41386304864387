import React from 'react';
import { MarkdownContent } from '@/components/markdown-content';
import { Box, Container, Grid, Typography, Link } from '@mui/material';
import { HomeSection3 } from '../../../../api/home';
import { Link as GatsbyLink, navigate } from 'gatsby';
import { LinkRightArrow } from '@/icons/LinkRightArrow';
import { MainColumn } from '@/components/main-column';
import { TrackedButton } from '@/components/tracked-button';
import { StyledImage } from '@/components/sections/home/Home.style';
import getGatsbyImageData from '@/utils/getGatsbyImageData';

export type Props = {
  strapiSection: HomeSection3;
};

export const Section3 = ({ strapiSection }: Props) => (
  <Container
    maxWidth={false}
    disableGutters
    sx={{ backgroundColor: 'greyscale.cultured' }}
  >
    <MainColumn
      sx={{
        pt: { xs: 8, md: 10 },
      }}
    >
      <Grid container justifyContent={'space-between'}>
        <Grid item xs={12} md={4} sx={{ mb: 6 }}>
          <MarkdownContent
            content={strapiSection?.paragraph}
            sx={{
              mb: 4,
              typography: 'body2',
              'h1, h2, h3, h4, h5, h6': {
                mb: 3,
              },
            }}
          />
          <TrackedButton
            variant="outlined"
            onClick={() => navigate(strapiSection?.button?.link)}
            sx={{ mb: 3 }}
          >
            {strapiSection?.button?.text}
          </TrackedButton>
        </Grid>
        <Grid item xs={12} md={8}>
          <Grid container spacing={5} sx={{ mb: 9 }}>
            {strapiSection?.services?.data?.map(
              ({ attributes: { icon, paragraph, name, button } }, idx) => {
                const imageData = getGatsbyImageData(icon?.data);
                return (
                  <Grid
                    key={`${icon?.data?.attributes?.url}${idx}`}
                    item
                    md={6}
                    xs={12}
                    sx={{
                      mb: { xs: 1, md: 0 },
                    }}
                  >
                    {imageData && (
                      <Box sx={{ mb: 3 }}>
                        <StyledImage
                          image={imageData}
                          alt={icon?.data?.attributes?.alternativeText || ''}
                        />
                      </Box>
                    )}
                    <Typography
                      sx={{
                        fontWeight: 'semiBold',
                        color: 'greyscale.persian',
                        mb: 0,
                      }}
                    >
                      {name}
                    </Typography>
                    <Box
                      sx={{
                        typography: 'body2',
                      }}
                    >
                      <MarkdownContent content={paragraph} />
                      <Link
                        component={GatsbyLink}
                        to={button.link}
                        sx={{
                          fontSize: 'body1.fontSize',
                          fontWeight: 'semiBold',
                          color: 'primary.main',
                        }}
                      >
                        {button.text} <LinkRightArrow />
                      </Link>
                    </Box>
                  </Grid>
                );
              },
            )}
          </Grid>
        </Grid>
      </Grid>
    </MainColumn>
  </Container>
);
